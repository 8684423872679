import { TeamMember } from "../types/company";

export const sortMembers = (members: TeamMember[]): TeamMember[] => {
  return members.sort((a, b) => {
    // Primary sort: has_logged_in (logged-in members first)
    if (a.has_logged_in && !b.has_logged_in) {
      return -1;
    }
    if (!a.has_logged_in && b.has_logged_in) {
      return 1;
    }

    // Secondary sort: email (alphabetical order)
    return a.email.localeCompare(b.email);
  });
};
