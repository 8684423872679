import useTranslation from "../../hooks/useTranslation";
import { MAX_SCALE_VALUE } from "../../utils/WorkplaceCulture.utils";
import getColorByScore from "../../utils/graphs";
import { handleBenchmarkingScore } from "../../utils/locale.utils";
import { ProgressArc } from "../ui/ProgressArc/ProgressArc";
import { Typography, TypographyVariant } from "../ui/Typography/Typography";
import { IconTypes } from "../ui/icons";
import { Icon } from "../ui/icons/Icon";
import "./ScoreArc.scss";

export interface ScoreArcProps {
  score: number;
  label?: string;
  linkLabel?: string;
  href?: string;
  size?: "small" | "medium" | "large";
}

const sizeMapping = {
  small: { scoreFontSize: "h2", labelFontSize: "body2" },
  medium: { scoreFontSize: "h1", labelFontSize: "body1" },
  large: { scoreFontSize: "display2", labelFontSize: "h3" },
};

export const ScoreArc = ({
  score,
  label,
  linkLabel,
  href,
  size = "medium",
}: ScoreArcProps) => {
  const { i18n } = useTranslation();

  const color = getColorByScore(score);
  const scoreLabel = handleBenchmarkingScore(i18n.language, score);

  const percentage = score / MAX_SCALE_VALUE;

  const { scoreFontSize, labelFontSize } = sizeMapping[size];

  return (
    <div className={`ScoreArc ScoreArc--${size}`}>
      <ProgressArc percentage={percentage} color={color} />
      <div className="ScoreArc__info">
        <Typography
          desktop={scoreFontSize as TypographyVariant}
          className="ScoreArc__info__score"
        >
          {scoreLabel}
        </Typography>
        {label && (
          <div className="ScoreArc__info__label">
            <Typography
              desktop={labelFontSize as TypographyVariant}
              color="secondary"
              weight="regular"
            >
              {label}
            </Typography>
            {href && <Icon type={IconTypes.Help} />}
          </div>
        )}
        {linkLabel && href && (
          <a
            href={href}
            target="_blank"
            rel="noreferrer"
            className="ScoreArc__info__label"
          >
            <Typography
              desktop={labelFontSize as TypographyVariant}
              color="secondary"
              weight="regular"
            >
              {linkLabel}
            </Typography>
            <Icon type={IconTypes.Help} />
          </a>
        )}
      </div>
    </div>
  );
};
