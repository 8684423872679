import { Grid, Typography } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import {
  GetSurveyChapterScoringResponse,
  SurveyChapterScoring,
} from "../../types/workplaceCulture";
import { ChapterLinkCard } from "../ChapterLinkCard/ChapterLinkCard";
import "./ChapterOverview.scss";

interface ChapterOverviewProps {
  chapterScoringData?: GetSurveyChapterScoringResponse;
  isLoading?: boolean;
  error?: boolean;
}

export const ChapterOverview = ({
  chapterScoringData,
  isLoading = true,
  error = false,
}: ChapterOverviewProps) => {
  const { t } = useTranslation("workplaceCulturePage");

  const loadingChapter = (id: string): SurveyChapterScoring => {
    return {
      id: id,
      name: "",
      description: "",
      image: "",
      demographic: {
        id: "",
        name: "",
        scores: {
          total_score: 0,
          filters: [],
        },
      },
    };
  };

  // Add fake loading chapters if data has not arrived yet
  const chapters = chapterScoringData
    ? chapterScoringData.chapters
    : [loadingChapter("ld-1"), loadingChapter("ld-2"), loadingChapter("ld-3")];

  return (
    <div className="ChapterOverview">
      <Typography variant="handwrittenH1" color="error">
        {t("surveyChapterOverview.title")}
      </Typography>
      <Typography color="secondary">
        {t("surveyChapterOverview.info")}
      </Typography>
      {error && (
        <Typography variant="h3" sx={{ mt: 2 }}>
          {t("surveyChapterOverview.error")}
        </Typography>
      )}
      <Grid container spacing={4} marginTop={1}>
        {!error &&
          chapters
            .sort(
              (chapterA, chapterB) =>
                (chapterA.demographic.scores.total_score || 0) -
                (chapterB.demographic.scores.total_score || 0)
            )
            .map((chapter) => {
              return (
                <Grid item md={4} sm={6} xs={12} key={chapter.id}>
                  <ChapterLinkCard chapter={chapter} isLoading={isLoading} />
                </Grid>
              );
            })}
      </Grid>
    </div>
  );
};
