import TrainingModule from "../models/trainingModule";

interface SortedTrainingModules {
  activeModules: TrainingModule[];
  inactiveModules: TrainingModule[];
}

export const sortTrainingModules = (
  trainingModules: TrainingModule[]
): SortedTrainingModules => {
  const sortedModules = [...trainingModules].sort((a, b) => {
    // If suggested_order is not set, put it at the end
    const orderA = a.suggested_order ?? Number.MAX_SAFE_INTEGER;
    const orderB = b.suggested_order ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });

  const activeModules = sortedModules.filter((obj) => obj.isAccessible());
  const inactiveModules = sortedModules.filter((obj) => !obj.isAccessible());

  return { activeModules, inactiveModules };
};
