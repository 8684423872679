import { useMutation } from "@tanstack/react-query";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CompanyService } from "../../api/CompanyService";
import { useAuth } from "../../hooks/useAuth";
import User from "../../models/user";
import { UpdateCompanyDetailsRequest } from "../../types/company";
import { AvailableLanguages } from "../../types/locale";
import { USER } from "../../utils/api.utils";
import { AcceptConditionStep } from "./AcceptConditionsStep";
import { CompanyNameStep } from "./CompanyNameStep";
import { IndustryStep } from "./IndustryStep";
import { LocationStep } from "./LocationStep";
import { NumberOfEmployeeStep } from "./NumberOfEmployeesStep";
import { PreferredCurrency } from "./PreferredCurrencyStep";
import { UserRoleStep } from "./UserRoleStep";
import "./SignUpSteps.scss";

// TODO: Add translations for this component
// TODO: Add tests for this component

export const SignUpSteps = () => {
  const { authInfo, setAuthInfo } = useAuth();
  const navigate = useNavigate();

  // In order of steps
  const [locations, setLocations] = useState<string[]>([]);
  const [userRole, setUserRole] = useState<string>("");
  const [industry, setIndustry] = useState("");
  const [numberOfEmployee, setNumberOfEmployee] = useState<number>(0);
  const [currency, setCurrency] = useState<string>("");
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [companyName, setCompanyName] = useState("");

  const [step, setStep] = useState(0);

  const companyService = new CompanyService();

  const updateCompanyDetailsMutation = useMutation({
    mutationFn: (data: UpdateCompanyDetailsRequest) => {
      return companyService.updateCompanyDetails(
        data.name,
        data.numberOfEmployees,
        data.industry,
        data.preferredCurrency
      );
    },
  });

  const acceptTOSMutation = useMutation({
    mutationFn: () => companyService.acceptTermsOfService(),
  });

  const acceptDPAMutation = useMutation({
    mutationFn: () => companyService.acceptDataProcessingAgreement(),
  });

  const handleSignUp = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    acceptTOSMutation.mutate();
    acceptDPAMutation.mutate();
    updateCompanyDetailsMutation.mutate(
      {
        name: companyName,
        numberOfEmployees: numberOfEmployee,
        industry: industry,
        preferredCurrency: currency,
      },
      {
        onSuccess: () => {
          const user = new User({
            name: authInfo.user?.name || "",
            email: authInfo.user?.email || "",
            language: authInfo.user?.language || AvailableLanguages.ENGLISH_GB,
            company: authInfo.user?.company || {
              name: "",
              subscriptions: [],
            },
          });

          setAuthInfo({
            user: user,
          });

          localStorage.setItem(USER, JSON.stringify(user));
          navigate("/dashboard");
        },
      }
    );
  };

  const renderSwitch = (step: number) => {
    switch (step) {
      case 0:
        return (
          <CompanyNameStep
            setStep={setStep}
            step={step}
            setCompanyName={setCompanyName}
            companyName={companyName}
          />
        );
      case 1:
        return (
          <LocationStep
            setStep={setStep}
            step={step}
            setLocations={setLocations}
            locations={locations}
            companyName={companyName}
          />
        );
      case 2:
        return (
          <UserRoleStep
            setStep={setStep}
            step={step}
            setUserRole={setUserRole}
            userRole={userRole}
            companyName={companyName}
          />
        );
      case 3:
        return (
          <IndustryStep
            setStep={setStep}
            step={step}
            setIndustry={setIndustry}
            industry={industry}
            companyName={companyName}
          />
        );
      case 4:
        return (
          <NumberOfEmployeeStep
            setStep={setStep}
            step={step}
            setNumberOfEmployee={setNumberOfEmployee}
            numberOfEmployee={numberOfEmployee}
            companyName={companyName}
          />
        );
      case 5:
        return (
          <PreferredCurrency
            setStep={setStep}
            step={step}
            setCurrency={setCurrency}
            currency={currency}
          />
        );
      case 6:
        return (
          <AcceptConditionStep
            setStep={setStep}
            step={step}
            setAgreeToTerms={setAgreeToTerms}
            agreeToTerms={agreeToTerms}
            handleSignUp={handleSignUp}
          />
        );
      default:
        return <></>;
    }
  };
  return <div>{renderSwitch(step)}</div>;
};
