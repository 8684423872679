import { Divider, Skeleton } from "@mui/material";
import useTranslation from "../../hooks/useTranslation";
import Gradient1 from "../../styles/illustrations/gradients/gradient_1.png";
import Gradient2 from "../../styles/illustrations/gradients/gradient_2.png";
import Gradient3 from "../../styles/illustrations/gradients/gradient_3.png";
import Gradient4 from "../../styles/illustrations/gradients/gradient_4.png";
import { TeamMember } from "../../types/company";
import { sortMembers } from "../../utils/members.utils";
import { NoDataState } from "../NoDataState/NoDataState";
import { TeamMemberInviteForm } from "../TeamMemberInviteForm/TeamMemberInviteForm";
import { Card } from "../ui/Card/Card";
import { Typography } from "../ui/Typography/Typography";
import "./TeamMembers.scss";

interface TeamMembersProps {
  teamMembers?: TeamMember[];
  isLoading: boolean;
  isError: boolean;
  onUserInviteUpdate: () => void;
}
export const TeamMembers = ({
  teamMembers,
  isLoading,
  isError,
  onUserInviteUpdate,
}: TeamMembersProps) => {
  const { t } = useTranslation("settingsPage");

  const getAvatar = (i: number) => {
    if (i % 4 === 0) {
      return Gradient1;
    } else if (i % 3 === 0) {
      return Gradient3;
    } else if (i % 2 === 0) {
      return Gradient2;
    }
    return Gradient4;
  };
  teamMembers && sortMembers(teamMembers);

  const renderLoading = () => {
    return (
      <div className="TeamMembers__list TeamMembers__list--loading">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="TeamMembers__list__item">
            <Skeleton variant="circular" width={40} height={40} />
            <Skeleton variant="text" width={"95%"} />
          </div>
        ))}
      </div>
    );
  };
  const renderError = () => (
    <NoDataState
      title={t("members.errors.fetch.title")}
      info={t("members.errors.fetch.info")}
    />
  );

  return (
    <Card className="TeamMembers">
      <Typography tagVariant="p" desktop="body2" weight="bold">
        {t("members.invite")}
      </Typography>
      <Divider className="TeamMembers__divider" />
      <TeamMemberInviteForm
        onUserInviteUpdate={onUserInviteUpdate}
        members={teamMembers?.map((member) => member.email) || []}
      />

      {teamMembers && teamMembers?.length > 0 && (
        <Typography
          tagVariant="p"
          desktop="body2"
          weight="bold"
          className="TeamMembers__title"
        >
          {t("members.title")}
        </Typography>
      )}
      {isLoading && renderLoading()}
      {isError && renderError()}
      <div className="TeamMembers__list">
        {teamMembers &&
          teamMembers.map((member, i) => (
            <div key={member.email}>
              <div className="TeamMembers__list__item">
                <div className="TeamMembers__list__item__details">
                  <img
                    src={getAvatar(i)}
                    alt=""
                    className="TeamMembers__list__item__details__avatar"
                  />
                  <Typography tagVariant="p" desktop="body1">
                    {member.email}
                  </Typography>
                </div>
                {!member.has_logged_in && (
                  <div className="TeamMembers__list__item__info">
                    <Typography tagVariant="p" desktop="caption">
                      {t("members.pending")}
                    </Typography>
                  </div>
                )}
              </div>
              {i < teamMembers.length - 1 && (
                <Divider style={{ margin: "1rem 0" }} />
              )}
            </div>
          ))}
      </div>
    </Card>
  );
};
