// TODO: update when real data from backend is ready
export enum TrainingAudience {
  "All" = "ALL_EMPLOYEES",
  "Management" = "MANAGEMENT",
}

export interface TrainingModuleResponse {
  audience: TrainingAudience;
  can_download: boolean;
  can_view: boolean;
  description: string;
  duration: string;
  is_ready: boolean;
  languages: string[];
  reference_id: string;
  suggested_order?: number;
  tags: string[];
  thumbnail: string;
  title: string;
  toolbox: string;
  touchpoints: TouchpointResponse[];
}

export interface TouchpointResponse {
  description: string;
  duration: string;
  order: number;
  preview_link: string;
  reference_id: string;
  thumbnail: string;
  title: string;
}
