import { AvailableLanguages, TAvailableSurveyLanguages } from "./locale";
import { SurveyQuestionResponse } from "./question";

export enum SurveyDispatchStatus {
  "InProgress" = "InProgress",
  "Ready" = "Ready",
  "Active" = "Active",
  "Latest" = "Latest",
  "Past" = "Past",
}

export interface SurveyDispatchReminderResponse {
  id: number;
  date: string;
}

export interface SurveyDispatchReminder {
  id: number | null;
  tempId?: number; // Used to identify the new reminder in the UI
  date: Date | undefined;
}

export interface GetSurveyDispatchResponse {
  survey_id: string;
  id: string;
  launch_date: string | undefined;
  due_date: string | undefined;
  reminders: SurveyDispatchReminderResponse[];
  participants: number | undefined;
  created_at: string | undefined;
  recipients: string[];
  is_mapped: boolean;
  is_public: boolean;
}

export interface GetSurveyResponse {
  reference_id: string;
  chapters: SurveyChapterResponse[];
  due_date: string;
  is_public?: boolean;
}

export interface PreviewSurveyResponse {
  reference_id: string;
  chapters: SurveyChapterResponse[];
  due_date: string;
}

export interface SurveyAnswer {
  question: string;
  options: string[];
}

export interface SubmitSurveyBody {
  answers: SurveyAnswer[] | undefined;
}

export interface SurveyChapterResponse {
  reference_id: string;
  title: string;
  order: number;
  description?: string | undefined;
  trigger_warning?: string | undefined;
  questions: SurveyQuestionResponse[];
  image?: string | undefined;
  hidden: boolean;
}

export interface AddressBookResponse {
  emails: string[];
}

export interface CustomQuestionAddBody {
  text: string;
  label: string;
  options: ICustomQuestionOption[];
}

export interface CustomQuestionUpdateBody {
  text: string;
  label: string;
  options: ICustomQuestionOption[];
}

export interface CustomQuestionsResponse {
  questions: CustomQuestion[];
}

export interface CustomQuestion {
  pk: string;
  text: string;
  label: string;
  options: ICustomQuestionOption[];
}

export interface ICustomQuestionOption {
  pk: string | null;
  text: string;
  label: string;
}

export interface Option {
  reference_id?: string;
  text: string;
  label: string;
}

export interface OptionMapping {
  mapping: string;
  label: string;
  options: Option[];
}

export interface GetMappedOptions {
  mapped_options: OptionMapping[];
}

export interface MappedOption {
  mapping: string;
  option: Option;
}

export enum SurveyErrorCodes {
  CLOSED = "SURVEY_CLOSED",
  INVALID = "INVITE_TOKEN_INVALID",
  ALREADY_USED = "TOKEN_ALREADY_USED",
}

export interface SurveyEmailLanguage {
  html_body: string;
  language_code: AvailableLanguages;
  name: string;
  native_name: string;
  subject: string;
}

export interface SurveyEmailContent {
  html_body: string;
  language_code: TAvailableSurveyLanguages;
  order: number;
  subject: string;
  text_body: string;
}

export interface SurveyEmailResponse {
  content: SurveyEmailContent[];
  custom_content: string;
  dispatch_id: string;
  reminder_id: string;
  survey_email_id: string;
  type: string;
}

// Body in post request for updating survey email
export interface SurveyEmailBody {
  content: {
    language_code: AvailableLanguages;
    order?: number;
  }[];
  custom_content?: string;
  survey_email_id: string;
}
